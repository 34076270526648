import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'next/link';
import Brand from '../brand';
import PathMap from '~/shared/pathMap';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';
import { useCountryCode } from '~/lib/hooks/useCountryCode';

const Footer = ({ className }) => {
  const { t } = useTranslation();
  const country = useCountryCode();

  return (
    <section className={classNames(styles['site-footer'], className, 'bg-brand-black')}>
      <div className="container">
        <footer className="flex flex-col gap-y-4">
          <div className="grid grid-cols-1 md:grid-cols-3 justify-items-center md:justify-items-start gap-y-8 md:gap-y-0">
            <Brand theme="white" />
            <div className="col-span-2">
              <b>{t('footerByIgusText')}&reg;</b>
              <br />
              <a id="footer-email" href={`mailto:${t('footerInfoMail')}`}>
                {t('footerInfoMail')}
              </a>
            </div>
          </div>
          <div className="grid grid-cols-3 mt-8">
            <div className="pb-4">
              <b>{t('footerComponentsTitle')}</b>
              <br />
              <Link id="footer-components-robots" href={PathMap.components.as(['robots'])}>
                {t('footerRobotsLinkText')}
              </Link>
              <br />
              <Link id="footer-components-end-effectors" href={PathMap.components.as(['end-effectors'])}>
                {t('footerEndeffectorsLinkText')}
              </Link>
              <br />
              <Link id="footer-components-control-systems" href={PathMap.components.as(['control-system'])}>
                {t('footerControlLinkText')}
              </Link>
              <br />
              <Link id="footer-components-vision-sensors" href={PathMap.components.as(['vision-sensors'])}>
                {t('footerVisionLinkText')}
              </Link>
              <br />
              <Link id="footer-components-pneumatics" href={PathMap.components.as(['pneumatics'])}>
                {t('footerPneumaticLinkText')}
              </Link>
              <br />
              <Link id="footer-components-software" href={PathMap.components.as(['software'])}>
                {t('footerSoftwareLinkText')}
              </Link>
              <br />
              <Link id="footer-components-service" href={PathMap.components.as(['service'])}>
                {t('footerServiceLinkText')}
              </Link>
              <br />
              <Link id="footer-components-accessories" href={PathMap.components.as(['accessories'])}>
                {t('footerAccessoriesLinkText')}
              </Link>
              <br />
            </div>
            <div className="pb-4">
              <b>{t('footerInformationTitle')}</b>
              <br />
              <Link id="footer-solutions" href={PathMap.solutions.as()}>
                {t('footerUseCasesLinkText')}
              </Link>
              <br />
              <Link id="footer-help" href={PathMap.help.as()}>
                {t('footerFAQLinkText')}
              </Link>
              <br />
              <Link id="footer-partners" href={PathMap.partners.as()}>
                {t('footerPartnerLinkText')}
              </Link>
              <br />
              <Link id="footer-contact" href={PathMap.contact.as()}>
                {t('footerContactLinkText')}
              </Link>
              <br />
              <a id="footer-newsletter" href={PathMap.newsletter.as()}>
                {t('footerNewsletterLinkText')}
              </a>
              <br />
              <Link id="footer-integrator-contact" href={PathMap.integratorContact.as([''])}>
                {t('footerIntegratorLinkText')}
              </Link>
              <br />
            </div>
            <div className="col-xs-1 col-sm-6 col-md-3 col-lg-4 pb-4">
              <b>{t('footerLegalTitle')}</b>
              <br />
              <Link id="footer-imprint" href={PathMap.imprint.as()}>
                {t('footerImprintLinkText')}
              </Link>
              <br />
              <Link id="footer-data-policy" href={PathMap.dataPolicy.as()}>
                {t('footerDataPolicyLinkText')}
              </Link>
              <br />
            </div>
          </div>
          {country == 'CN' && (
            <p className="flex flex-row gap-x-2">
              <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">沪ICP备2021012961号</a>
              <div className="self-center">
                <img
                  src="images/footer/PoliceBeianLogo.png?s=A639704809A367EF8D12E5F202705632E05051AB"
                  alt="PoliceBeianLogo.png"
                  className=""
                />
              </div>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31012002005502">
                沪公网安备 31012002005502号
              </a>
            </p>
          )}
        </footer>
      </div>
    </section>
  );
};

Footer.defaultProps = {
  className: ''
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
