import { useQuery } from '@tanstack/react-query';
import { RequestApi } from '../api-client';

export const COMPATIBILITY_QUERY_KEY = 'compatibility';

export const useCompatibility = (sku: string) => {
  return useQuery([COMPATIBILITY_QUERY_KEY, sku], () =>
    RequestApi<{
      isCompatibleToBundle: boolean;
      isPartOfBundle: boolean;
    }>({
      method: 'post',
      url: `/bundle/products/${sku}/compatibility`,
      data: {
        productSku: sku
      }
    })
  );
};
