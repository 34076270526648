import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import Router from 'next/router';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import * as Sentry from '@sentry/nextjs';
import type { GetServerSidePropsContext, GetStaticPropsContext } from 'next';
import { DomainService, CountryCode } from 'shared';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const generateId = require('nanoid/async');

function getLanguage(context?: GetStaticPropsContext | GetServerSidePropsContext): string {
  let language = 'en-GB';
  if (context) {
    if (context.locale) {
      language = context.locale;
    } else if (context.defaultLocale) {
      language = context.defaultLocale;
    }
  } else if (typeof window !== 'undefined') {
    if (Router.locale) {
      language = Router.locale;
    } else if (Router.defaultLocale) {
      language = Router.defaultLocale;
    }
  }
  return language;
}

function getCountryCode(context?: GetStaticPropsContext | GetServerSidePropsContext): CountryCode {
  let host: string;

  if (context && 'req' in context) {
    host = context.req.headers['host'] as string;
  } else if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    const url = new URL(origin);

    host = url.hostname;
  } else {
    // TODO: Fallback should depend on the environment (dev → GB, staging/production → US or error)

    console.warn('Could not determine host, defaulting to GB');

    return 'GB';
  }

  const mapped = DomainService.mapDomainToCountry(host);

  // TODO: Should this throw?
  if (!mapped) {
    console.warn('Invalid country from host, defaulting to GB', host);
    return 'GB';
  }

  return mapped;
}

export type AxiosRequestConfigWithCancel = AxiosRequestConfig & {
  cancelTokenSource?: CancelTokenSource;
  cancelOnRouteChange?: boolean;
};

async function ApiClient(
  axiosReq: AxiosRequestConfigWithCancel,
  serverContext?: GetStaticPropsContext | GetServerSidePropsContext
) {
  const countryCode = getCountryCode(serverContext);
  const countryApiDomain = DomainService.getApiDomainByRegion(countryCode);

  const axiosClient = axios.create({
    baseURL: `https://${countryApiDomain}/api/v1`,
    withCredentials: true
  });
  // Exponential back-off retry delay between requests
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /// @ts-ignore typings are off here
  axiosRetry(axiosClient, { retryDelay: exponentialDelay, retries: 3 });

  const transactionId = await generateId();
  Sentry.configureScope((scope) => {
    scope.setTag('transaction_id', transactionId);
  });

  axiosReq.headers = axiosReq.headers || {};

  if (serverContext && 'req' in serverContext) {
    if (serverContext.req.headers.cookie) {
      axiosReq.headers.cookie = serverContext.req.headers.cookie;
    }
    if (serverContext.req.headers['x-forwarded-for']) {
      axiosReq.headers['X-Forwarded-For'] = serverContext.req.headers['x-forwarded-for'];
    }
  }

  axiosReq.headers['X-Requested-With'] = 'XMLHttpRequest';
  axiosReq.headers['X-Transaction-ID'] = transactionId;
  axiosReq.params = {
    ...axiosReq.params,
    lng: getLanguage(serverContext),
    country: countryCode
  };

  let { cancelTokenSource } = axiosReq;
  if (!cancelTokenSource) {
    cancelTokenSource = axios.CancelToken.source();
    axiosReq.cancelToken = cancelTokenSource.token;
  } else {
    axiosReq.cancelToken = cancelTokenSource.token;
    delete axiosReq.cancelTokenSource;
  }

  const cancelRequestHandler = (err: any) => {
    if (axiosReq.cancelOnRouteChange) {
      if (err.cancelled) {
        cancelTokenSource?.cancel(`Request canceled due to "routeChange" cancellation event.`);
      }
    }
  };

  /**
   * Abort the request when the user change the page on the client
   * This ensure that the request is canceled when the user move to a different page
   */
  Router.events.on('routeChangeError', cancelRequestHandler);

  if (process.env.NODE_ENV !== 'test') {
    axiosClient.interceptors.response.use(
      (config) => config,
      function (error) {
        console.error(`Axios response error`, error);
        return Promise.reject(error);
      }
    );
  }

  try {
    const resp = await axiosClient(axiosReq);
    Router.events.off('routeChangeError', cancelRequestHandler);
    return resp.data;
  } catch (error) {
    Router.events.off('routeChangeError', cancelRequestHandler);
    throw error;
  }
}

/**
 * Used in classic react classes or in getInitialProps. You have to cancel the request by your own
 *
 * @param {*} axiosReq
 * @param {*} serverReq
 * @returns {*} The api client
 */
export default function apiClient<T = any>(
  axiosReq: AxiosRequestConfigWithCancel,
  serverContext?: GetStaticPropsContext | GetServerSidePropsContext
) {
  return ApiClient(axiosReq, serverContext) as Promise<T>;
}

export const RequestApi = apiClient;
