import type { Facet, Product } from '~/types';
import queryString from 'querystring';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RequestApi } from '../api-client';
import { useRouter } from 'next/router';
import { COMPATIBILITY_QUERY_KEY } from './useCompatibility';

export const PRODUCTS_QUERY_KEY_PREFIX = 'products';

type UseProductOptions = {
  categorySlug?: string;
  useInitialData?: boolean;
  initialFacets?: Facet[];
  initialProducts?: Product[];
};

export function useInvalidateProducts() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([PRODUCTS_QUERY_KEY_PREFIX]);
    queryClient.invalidateQueries([COMPATIBILITY_QUERY_KEY]);
  };
}

export function useProducts(options?: UseProductOptions) {
  const { categorySlug, initialFacets, initialProducts, useInitialData } = options ?? {};

  const router = useRouter();

  const categorySlugToUse = (categorySlug ?? (router.query.categorySlug as string)).replaceAll('/', '');

  const query = queryString.stringify({
    filter: router.query.filter
  });

  const { data, refetch, isLoading, isFetching } = useQuery(
    [PRODUCTS_QUERY_KEY_PREFIX, categorySlugToUse, query, router.locale],
    async () => {
      const { products, facets } = await RequestApi<{ products: Array<Product>; facets: Array<Facet> }>({
        method: 'GET',
        url: `/catalog/${categorySlugToUse}/components?${query}`
      });
      return { facets, products };
    },
    {
      enabled: router.isReady,
      // no need to refetch
      refetchOnWindowFocus: false,
      // we consider data fresh for 15 minutes (we don't update products that often)
      staleTime: 1000 * 60 * 15,
      initialData: useInitialData
        ? {
            products: initialProducts ?? [],
            facets: initialFacets ?? []
          }
        : undefined
    }
  );

  return {
    products: data?.products ?? [],
    facets: data?.facets ?? [],
    refetch,
    isLoading,
    isFetching: isFetching
  };
}
