import { useState } from 'react';
import { useAppStore } from '~/store/app';
import type { Bundle } from '~/types';
import { useInvalidateProducts } from './useProducts';

export function useBundle() {
  const [bundleLoading, setBundleLoading] = useState<boolean | undefined>(undefined);
  const [showBundle, setShowBundle] = useState<boolean | undefined>(undefined);
  const { cartCount, updateCartCount } = useAppStore();
  const invalidateProducts = useInvalidateProducts();

  const handleRobotBundleTabClick = () => {
    setShowBundle(true);
    setBundleLoading(true);
  };

  const handleBundleLoad = (bundle: Bundle) => {
    setBundleLoading(false);
    updateCartCount(bundle?.productCount || 0);
  };

  const handleBundleClose = () => {
    setBundleLoading(false);
    setShowBundle(false);
  };

  const handleBundleUpdate = (bundle: Bundle) => {
    updateCartCount(bundle?.productCount || 0);

    invalidateProducts();
  };

  return {
    bundleLoading,
    showBundle,
    handleRobotBundleTabClick,
    handleBundleLoad,
    handleBundleClose,
    handleBundleUpdate,
    cartCount,
    setBundleLoading: (value: boolean) => setBundleLoading(value),
    setShowBundle: (value: boolean) => setShowBundle(value)
  };
}
