import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface AppState {
  cartCount: number;
  updateCartCount: (count: number) => void;
}

export const useAppStore = create<AppState>()(
  devtools((set) => ({
    cartCount: 0,
    updateCartCount: (newCount) => set({ cartCount: newCount })
  }))
);
